import styled from 'styled-components'
import { breakpoint } from '../../../Common/Helpers/breakpoint'
import theme from '../../../theme'

const { breakpoints: { md } } = theme

const LoginFormStyled = styled.div`
  padding-bottom: 50px;
 
  & .ant-form-vertical .ant-form-item-label {
      padding: 0 0 2px;
  }

  .form-link {
    font-size: 16px;
    color: ${ theme.colors.additional1 };
    text-decoration: underline;
    padding-top: 16px;

    @media (${ breakpoint(md) }) {
      font-size: 14px;
      padding-top: 0;
    }
  }

  .form-input {
    padding-bottom: ${ theme.spacing.desktop.g }px;
    
    @media (${ breakpoint(md) }) {
      padding-bottom: 4px;
    }
  }
  
  .action-group {
    margin-top: 16px;
    width: 100%;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;

    .ant-btn {
      width: 100%;
    }

    @media (${ breakpoint(md) }) {
      flex-direction: row;

      .ant-btn {
        width: unset;
      }
    }
  }

`

export default LoginFormStyled
