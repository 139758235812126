/* eslint-disable max-len */
import {
  Auth, CookieStorage
} from '@aws-amplify/auth'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { getSession } from './Common/Utils/Auth/AuthCookies'
import CONFIG from './config.json'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { login } from './Routers/LoginRouter/LoginRouter'
import Cookie from 'js-cookie'

const {
  userPoolId, userPoolWebClientId, identityPoolId
} = CONFIG.cognito

Auth.configure({
  region: 'eu-west-2',
  userPoolId,
  userPoolWebClientId,
  identityPoolId,
  cookieStorage: new CookieStorage({
    domain: getSharedHostname(),
    sameSite: 'lax'
  })
})

function getSharedHostname(){
  const hostname = window.location.hostname

  if (hostname === 'localhost') {
    return hostname
  }

  return hostname.substring(hostname.indexOf('.'))
}

/**
 * Delete authentication cookies that may remain in browser even after update.
 * These cookies will not be set or consumed after the Nov 2023 updates.
 * Cookies can persist for up to 400 days. This code can eventually be removed.
 */
function removeOldCookies(){
  const cookieNames = [
    'elias-login-email', 'elias-login-password', 'login-email', 'login-password'
  ]

  cookieNames.forEach(name => Cookie.remove(name))
}

async function run(){
  removeOldCookies()
  const queryParams = new URLSearchParams(window.location.search)
  const signout = queryParams.get('signout')
  const session = await getSession({ Auth })

  if (session && signout) {
    console.info('Login app - signing out')

    await Auth.signOut()
  } else if (session) {
    console.info('Login app - logging in with existing session')

    login()
  }

  const root = ReactDOM.createRoot(document.getElementById('root'))

  root.render(<React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>)

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

run()
