import { Auth } from '@aws-amplify/auth'
import {
  Input, Button,
  Heading,
  textVariants
} from '@reams/components'
import {
  Input as AntInput,
  Form, notification
} from 'antd'
import React from 'react'
import {
  Link, useNavigate
} from 'react-router-dom'
import { getSession } from '../../../Common/Utils/Auth/AuthCookies'
import theme from '../../../theme'
import LoginFormStyled from './LoginFormStyled'

const LoginForm = ({
  setCognitoUser, onLogin
}) => {
  const navigate = useNavigate()
  const [ form ] = Form.useForm()
  const handleLogin = async (values) => {
    const {
      email, password
    } = values

    try {
      const session = await getSession({ Auth })

      if (session) {
        // Sign out in case another user's cookies were loaded in a separate tab
        await Auth.signOut()
      }

      const cognitoUser = await Auth.signIn(email, password)
      const { challengeName } = cognitoUser

      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.info('NEW_PASSWORD_REQUIRED', cognitoUser)

        setCognitoUser(cognitoUser)
        navigate('/reset')

        return
      }

      onLogin()
    } catch (e) {
      console.info('Auth.signIn', e)
      if (e.code === 'PasswordResetRequiredException') {
        console.info('PasswordResetRequiredException', e)
        navigate('/forgot')

        return
      }
      notification.warning({
        message: 'Login failed',
        description: e.message
      })
    }
  }

  return (
    <LoginFormStyled>
      <Heading
        className="header-spacing"
        color={theme.colors.primary}
        variant={textVariants.H2}
      >
        Login
      </Heading>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={async (credentials) => {
          await handleLogin({
            ...credentials,
            email: credentials.email.toLowerCase()
          })
        }}
        onFinishFailed={(x) => console.info(x)}
      >
        <Form.Item
          rules={[ {
            required: true,
            message: 'Please input your e-mail address',
            type: 'email'
          } ]}
          className="form-input"
          label="Email"
          name="email"
        >
          <Input size="default" />
        </Form.Item>

        <Form.Item
          rules={[ {
            required: true,
            message: 'Please input your password'
          } ]}
          className="form-input"
          label="Password"
          name="password"
        >
          <Input variant={AntInput.Password} />
        </Form.Item>

        <Form.Item className="action-group">
          <div className="actions">
            <Link
              className="form-link"
              to="/forgot">
              Forgot password?
            </Link>

            <Button
              type="primary"
              // loading={authInProgress}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Login
            </Button>
          </div>
        </Form.Item>
      </Form>
    </LoginFormStyled>
  )
}

export default LoginForm
