import { Auth } from '@aws-amplify/auth'
import {
  Input, Button,
  Heading,
  textVariants
} from '@reams/components'
import {
  Form, notification, Input as AntInput
} from 'antd'
import React from 'react'
import {
  Link, useLocation
} from 'react-router-dom'
import theme from '../../../theme'
import LoginFormStyled from './LoginFormStyled'


const ResetPassword = ({
  cognitoUser, setCognitoUser, onLogin
}) => {
  const [ form ] = Form.useForm()

  function handleChangePassword(values){
    console.log(values)
    const { newPassword } = values

    Auth.completeNewPassword(cognitoUser, newPassword)
      .then(() => {
        setCognitoUser()
        onLogin()
      })
      .catch((err) => {
        const { message } = err

        notification.warning({
          message: 'Creating new password failed',
          description: message
        })
      })
  }

  console.log(cognitoUser)

  return (
    <LoginFormStyled>
      <Heading
        className="header-spacing"
        color={theme.colors.primary}
        variant={textVariants.H2}
      >
        Create New Password
      </Heading>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleChangePassword}
      >
        <Form.Item
          rules={[ {
            required: true,
            message: 'Please input your new password'
          } ]}
          hasFeedback
          label="New Password"
          name="newPassword"
        >
          <Input variant={AntInput.Password} />
        </Form.Item>

        <Form.Item
          rules={[ {
            required: true,
            message: 'Please confirm your password'
          },
          ({ getFieldValue }) => ({ validator(_, value){
            if (!value || getFieldValue('newPassword') === value) {
              return Promise.resolve()
            }

            return Promise.reject(new Error('The two passwords that you entered do not match!'))
          } }) ]}
          dependencies={[ 'newPassword' ]}
          hasFeedback
          label="Confirm Password"
          name="confirmPassword"
        >
          <Input variant={AntInput.Password} />
        </Form.Item>

        <div className="actions">
          <Link
            className="form-link"
            to="/login">
            Back to Login
          </Link>

          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault()
              form.submit()
            }}
            // loading={authInProgress}
          >
            Confirm
          </Button>
        </div>
      </Form>
    </LoginFormStyled>
  )
}

export default ResetPassword
