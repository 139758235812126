const ROLES = {
  LEAD: 'lead',
  PROJECT_MANAGER: 'pm', // Manage own projects | create by PM, Suppliers from the same account and REAMS admin only
  CUSTOMER: 'customer', // (Analyst) Client portal and analytics access
  SURVEYOR: 'surveyor', // (AV) Asset Verification Surveyor
  FABRIC: 'fabric', // (Condition) Condition Surveyor
  SUPPLIER: 'supplier',
  ADMIN: 'admin',
  APPROVER: 'approver',
  MOBILE_LITE: 'mobileLite',
  DATA_IMPORT: 'dataImport'
}

module.exports = { ROLES }
